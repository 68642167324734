import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/Layout/Layout'
import './ContactUsCountryCode.scss'
import ArticleLayout, {
  RecentArticleTypes,
} from '../../layouts/ArticleLayout/ArticleLayout'
import CONTACT_US_NAVIGATION from '../../data/contact-us-navigation.json'
import Seo from '../../components/Seo/Seo'
import { toKebabCase } from '../../services/caseTransform'
import ContactUsLayout from '../../layouts/ContactUsLayout/ContactUsLayout'

const COUNTRY_CODES = [
  {
    country: 'Japan C&W-IDC',
    code: '010 or 0061+010*',
  },
  { country: 'Japan JT', code: '010 or 0041_010*' },
  { country: 'Korea', code: '001, 002 or 008' },
  { country: 'Australia', code: '0011' },
  { country: 'Malaysia', code: '00' },
  { country: 'Canada', code: '011' },
  { country: 'New Zealand', code: '00' },
  { country: 'Singapore', code: '001' },
  { country: 'France', code: '00' },
  { country: 'Spain', code: '00' },
  { country: 'Germany', code: '00' },
  { country: 'Switzerland', code: '00' },
  { country: 'Hongkong', code: '001 or 002' },
  { country: 'Taiwan', code: '00' },
  { country: 'Italy', code: '00' },
  { country: 'Thailand', code: '001' },
  { country: 'Japan KDDI', code: '010 or 001_010*' },
  { country: 'UK', code: '00' },
  { country: 'Japan NTT', code: '010 or 0033+010*' },
]

type ContactUsCountryCodePropTypes = {
  data: {
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
    allShowroom: {
      nodes: {
        showRoomTitle: string
      }[]
    }
  }
}

const ContactUsCountryCode = ({
  data: {
    recentArticles: { nodes: recentArticles },
    allShowroom: { nodes: allShowroom },
  },
}: ContactUsCountryCodePropTypes): ReactElement => {
  const initialShowroom = allShowroom[0]
  const showroomUrl = toKebabCase(initialShowroom.showRoomTitle)

  const contactUsNavigation = CONTACT_US_NAVIGATION.map((item) => {
    return {
      url: item?.url,
      label: item.label,
      items: item.items?.map((val) => {
        return {
          url:
            val.url === 'contact-us/showroom'
              ? `contact-us/showroom/${showroomUrl}`
              : val.url,
          label: val.label,
        }
      }),
    }
  })

  return (
    <Layout>
      <Seo
        slug="contact-us/country-code"
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/contact-us/country-code/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, country code list',
        }}
        title="Contact Us | Country Code"
      />
      <ContactUsLayout className="contact-us-country-code">
        <ArticleLayout
          title="Contact Us"
          asideNavItems={contactUsNavigation}
          asideArticleTitle="News & Events"
          breadcrumbs={[
            {
              label: 'Contact Us',
              url: 'contact-us',
            },
            {
              label: 'Country Codes',
            },
          ]}
          recentArticles={recentArticles}
        >
          <h2 className="contact-us-country-code-title">Country Codes</h2>
          <ul className="contact-us-country-code-list">
            {COUNTRY_CODES.map((countryCode) => (
              <li key={countryCode.country}>
                <strong>{countryCode.country}</strong>
                <span>{countryCode.code}</span>
              </li>
            ))}
          </ul>
        </ArticleLayout>
      </ContactUsLayout>
    </Layout>
  )
}

export default ContactUsCountryCode

export const pageQuery = graphql`
  query {
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
    allShowroom(filter: { type: { eq: "" } }) {
      nodes {
        showRoomTitle
      }
    }
  }
`
